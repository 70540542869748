
export const Unauthorized = () => {
    return (
        <main className="min-h-screen h-full flex items-center px-6 lg:px-32 bg-purple-900 text-white relative">
            <header className="w-full absolute left-0 top-0 p-6 lg:p-32">
            </header>
            <section className="w-full md:w-9/12 xl:w-8/12">
                <span className="font-bold uppercase tracking-widest">401</span>
                <h1 className="text-3xl lg:text-5xl font-bold text-pink-500">
                    Not<br/>Authorized
                </h1>
            </section>
        </main>
    )
}