export const adminProductId = '612358238b17d4e686036d56';
export const webEditorUsageFeatureCode = 'WUC';

export const paginationPageSize = 20;
export const paginationMaxPages = 10;

export const disabledState = "disabled";
export const focusedState = "focused";
export const normalState = "normal";

export const previewPdfLinkId = "previewPdfLinkId";
export const previewHtmlLinkId = "previewHtmlLinkId";
export const localPrintViewpointPdfLinkId = "localPrintViewpointPdfLinkId";
export const localPrintDynamoPdfLinkId = "localPrintDynamoPdfLinkId";

export const menuState = {
    edit: 'edit',
    attachment: 'attachment',
}

export const sideBarState = {
    closed: 'closed',
    predefinedTexts: 'predefinedTexts',
    textLibrary: 'textLibrary',
}

export const distribution = {
    notSet: 'CentralDistribution',
    print: 'Print',
    kivra: 'Kivra',
    digipost: 'Digipost',
    localPrint: 'LocalPrint',
    email: 'Email',
    archiving: 'Archiving',
}

export const distributionArchiveMethod = {
    viewpoint: 'Viewpoint',
    onprem: 'Onprem',
}

export const fileStatus = {
    draft: 'Draft',
    ok: 'OK',
    discarded: 'Discarded',
    deleted: 'Deleted',
}

export const editorType = {
    undefined: 'Undefined',
    file: 'File',
    root: 'Root',
    fontDef: 'FontDef',
    colorDef: 'ColorDef',
    tableDef: 'TableDef',
    imageDef: 'ImageDef',
    formDef: 'FormDef',
    doc: 'Doc',
    index: 'Index',
    page: 'Page',
    text: 'Text',
    table: 'Table',
    textChoice: 'TextChoice',
    textPart: 'TextPart',
    font: 'Font',
    textColor: 'TextColor',
    paragraphProperties: 'ParagraphProperties',
    paragraph: 'Paragraph',
    tabset: 'Tabset',
    run: 'Run',
    inputField: 'InputField',
    hyperLink: 'HyperLink',
    mL: 'ML',
    nL: 'NL',
    keep: 'Keep',
    list: 'List',
    listItem: 'ListItem',
    writeEnable: 'WriteEnable',
    image: 'Image',
    insertPoint: 'InsertPoint',
    tableColGroup: 'TableColGroup',
    tableCol: 'TableCol',
    tableColumn: 'TableColumn',
    tableRow: 'TableRow',
    heightSpace: 'HeightSpace',
    svg: 'Svg',
    svgLine: 'SvgLine',
    svgRectangle: 'SvgRectangle',
    svgPath: 'SvgPath',
    svgImage: 'SvgImage',
    svgText: 'SvgText',
    breakTable: 'BreakTable',
    breakTableFirstHeader: 'BreakTableFirstHeader',
    breakTableRunningHeader: 'BreakTableRunningHeader',
    breakTableLastFooter: 'BreakTableLastFooter',
    breakTableRunningFooter: 'BreakTableRunningFooter',
    breakTableBody: 'BreakTableBody',
}

export const inputFieldType = {
    text: 'Text',
    integer: 'Integer',
    decimal: 'Decimal',
    checkbox: 'Checkbox',
    select: 'Select',
    date: 'Date',
}

export const copyState = {
    inactive: 0,
    active: 1,
    expired: 2,
}

export const formatButtonState = {
    disabledAndUnfocused: 0,
    disabledAndFocused: 1,
    enabledAndUnfocused: 2,
    enabledAndFocused: 3,
}

export const auditEventLogType = {
    openDocument: 0,
}
