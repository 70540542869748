import { UserAgentApplication } from "@azure/msal";
import { useEffect } from "react"

// ToDO: remove
// just for testing purposes. 
export default function Msal() {

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        const config = {
            auth: {
                clientId: "1dff2de1-562a-47e3-8b18-7d32ed1b6893",
                authority: 'https://login.microsoftonline.com/common',
                scopes: 'offline_access user.read',
                redirectUri: 'http://localhost:3012/aadlogin',
            }
        }
        // applicationConfig.authority, null, {
        //   storeAuthStateInCookie: true,
        //   cacheLocation: "localStorage"
        var myMSALObj = new UserAgentApplication(config);
        console.log(myMSALObj);
        myMSALObj.loginPopup({
            scopes: config.auth.scopes.split(' '),
            redirectUri: config.auth.redirectUri,

        }).then(function (idToken) {
            console.log('idToken', idToken)
            myMSALObj.acquireTokenSilent({
                scopes: config.auth.scopes.split(' '),
                redirectUri: config.auth.redirectUri,

            }).then(function (accessToken) {
                console.log('accessToken',accessToken);
            });
        }, function (error) {
            console.log(error);
        });
    }

    return (
        <div>msal</div>
    )
}